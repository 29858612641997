<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  Bars3Icon,
  XMarkIcon,
  ArrowLeftCircleIcon,
  Cog8ToothIcon,
  ArrowLeftIcon,
} from '@heroicons/vue/24/outline'
import { version as projectVersion } from '~/package.json'

const router = useRouter()

// Navigation links
type NavigationRoute = {
  name: string
  href: string
  icon: typeof Cog8ToothIcon
  current: boolean
}

const navigationLinks: NavigationRoute[] = reactive([
  {
    name: 'Actions Trident',
    href: '/admin',
    icon: Cog8ToothIcon,
    current: false,
  },
])

const isSidebarOpen = ref<boolean>(false)
const isSidebarSmall = ref<boolean>(false)

// Watching the route to update the current navigation link
watch(router.currentRoute, () => {
  const route = router.currentRoute
  navigationLinks.forEach(navRoute => {
    navRoute.current = navRoute.href === route.value.path
  })
}, { immediate: true })
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="isSidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="isSidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 flex z-40">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex-1 flex flex-col max-w-xs w-full bg-sky-blue-700">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="isSidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div class="flex-shrink-0 flex items-center px-4 gap-3">
                  <Logo class="h-8 w-auto" />
                  <h1 class="text-xl font-semibold text-gray-100">
                    AppTrident Admin
                    <small class="text-xs text-gray-300">v{{ projectVersion }}</small>
                  </h1>
                </div>
                <nav class="mt-5 px-2 space-y-1">
                  <p class="flex items-center space-x-0 !mt-4 !mb-2 font-semibold text-sm text-sky-blue-200">
                    <span>Paramètres</span>
                  </p>
                  <NuxtLink v-for="item in navigationLinks" :key="item.name" :to="item.href" @click="(isSidebarOpen = false)" :class="[item.current ? 'bg-sky-blue-800 text-white' : 'text-white hover:bg-sky-blue-600 hover:bg-opacity-75', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                    <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-sky-blue-300" aria-hidden="true" />
                    {{ item.name }}
                  </NuxtLink>
                </nav>
              </div>
              <div class="flex-shrink-0 flex border-t border-sky-blue-800 p-4">
                <div @click="navigateTo('/')" class="flex-shrink-0 group block cursor-pointer">
                  <div class="flex items-center">
                    <div>
                      <ArrowLeftIcon class="inline-block h-6 w-6 text-sky-blue-300" />
                    </div>
                    <div class="ml-3">
                      <p class="text-sm font-medium text-white">Retour sur le site</p>
                    </div>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div :class="isSidebarSmall ? 'md:w-14' : 'md:w-64'" class="hidden md:flex md:flex-col md:fixed md:inset-y-0 z-30 transition-all duration-200">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-sky-blue-700">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto overflow-x-hidden">
          <div class="flex items-center flex-shrink-0 px-2 gap-3">
            <Logo :class="isSidebarSmall ? 'hidden' : ''" class="pl-2 h-10 w-auto" />
            <h1 :class="isSidebarSmall ? 'hidden' : ''" class="text-lg font-semibold text-gray-100 leading-7">
              AppTrident Admin
              <small class="text-xs text-gray-300">v{{ projectVersion }}</small>
            </h1>
            <button @click="isSidebarSmall = !isSidebarSmall" type="button" class="p-2 inline-flex items-center justify-center rounded-md text-gray-100 hover:text-gray-300">
              <ArrowLeftCircleIcon :class="isSidebarSmall ? 'rotate-180': ''" class="h-6 w-6 transition-transform duration-200" aria-hidden="true" />
            </button>
          </div>
          <nav class="mt-5 flex-1 px-2 space-y-1">
            <p class="flex items-center space-x-0 !mt-4 !mb-2 pl-1 font-semibold text-sm text-sky-blue-200">
              <span :class="isSidebarSmall ? 'hidden' : ''">Paramètres</span>
            </p>
            <NuxtLink v-for="item in navigationLinks" :key="item.name" :href="item.href" :class="[item.current ? 'bg-sky-blue-800 text-white' : 'text-white hover:bg-sky-blue-600 hover:bg-opacity-75', 'group flex gap-x-3 items-center px-2 py-2 text-sm font-medium rounded-md']">
              <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-sky-blue-300" aria-hidden="true" />
              <span :class="isSidebarSmall ? 'hidden' : ''">{{ item.name }}</span>
            </NuxtLink>
          </nav>
        </div>
        <div class="flex-shrink-0 flex border-t border-sky-blue-800 p-4">
          <div @click="navigateTo('/')" class="flex-shrink-0 w-full group block cursor-pointer">
            <div class="flex items-center">
              <div>
                <ArrowLeftIcon class="inline-block h-6 w-6 text-sky-blue-300" />
              </div>
              <div :class="isSidebarSmall ? 'hidden' : ''" class="ml-3">
                <p class="text-sm font-medium text-white">Retour sur le site</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="isSidebarSmall ? 'md:pl-14' : 'md:pl-64'" class="flex flex-col flex-1 transition-all duration-200">
      <div class="flex items-center sticky w-full box-border top-0 z-10 md:hidden pl-1 sm:pl-3 bg-gray-100">
        <button type="button" class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-blue-500" @click="isSidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <Logo class="inline-flex h-6 w-auto" @click="isSidebarOpen = true" />
      </div>
      <main class="flex-1">
        <div class="py-6 px-4 sm:px-6 md:px-8">
          <!-- Content of the page -->
          <slot />
        </div>
      </main>
    </div>
  </div>
</template> 